<template>
  <div class="right-bar">
    <div class="right-bar__back" @click="changeToggleForgotPassword(false)"/>
    <div class="right-bar__content">
      <div class="right-bar__close" @click="changeToggleForgotPassword(false)"></div>`
      <div class="right-bar__title col-md-10">
        Lost Password
      </div>
      <div class="right-bar__form col-md-6">
        <div class="right-bar__form-block row">
          <label class="input-block col-md-8">
            <p>Enter your email here. We will send you an email with your username and a link to reset your password.</p>
          </label>
          <label class="input-block col-md-12">
            <input type="email" v-model="email"><span/>
            <span class="input-block__name" :class="email.length ?'active' : null" >E-mail</span>
            <small class="input-block__error" v-if="$v.email.$dirty && $v.email.$error">email must be valid</small>
          </label>
        </div>
        <div class="right-bar__form-block row">
          <div class="col-md-12 d-flex align-center">
            <button class="btn grey mr-3" @click.prevent="openChangePassword()">Get New Password</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import {email, required} from "vuelidate/lib/validators";

export default {
  data(){
    return{
      email:''
    }
  },
  validations: {
    email: {
      email,
      required
    },
  },
  methods:{
    openChangePassword(){

      this.$v.$touch();
      if(!this.$v.email.$error){
        this.sendRemindPassword({email: this.email}).then(res => {
        if(res.status){
          this.changeToggleForgotPassword(false);
          this.toggleAlertPopup({
            status: true,
            content: {
              title: 'Success!',
              text: 'We have sent a confirmation email to your mail.',
              type: "success"
            }
          });
        }else{
          this.toggleAlertPopup({
            status: true,
            content: {title:'Error', text: res.data.message, type:"Error!"}
          });
        }
      })
      }
    },
    ...mapActions({
      sendRemindPassword: 'auth/sendRemindPassword',
    }),
    ...mapMutations({
      changeToggleForgotPassword: 'general/changeToggleForgotPassword',
      changeToggleChangePassword: 'general/changeToggleChangePassword',
      toggleAlertPopup: 'general/toggleAlertPopup'
    })
  }
}
</script>
